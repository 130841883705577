@import url('https://fonts.googleapis.com/css2?family=Mate+SC&display=swap');

html {
  font-size: 62.5%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

body {
  background-image: url("../public/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  background-size: cover;
  color:white;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-size: 2.1rem;
  font-family: 'Mate SC', serif;
}

/*Animations*/
@keyframes textIn {
  0%{
      transform: translateY(100%);
      opacity : 0;
  }
  100%{
      transform: translateY(0%);
      opacity : 1;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12vh;
  background-image: url("../public/bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-size: cover;
  background-size: cover;
  z-index: 4;
  pointer-events: none;
}

.boxborder {
  position: fixed;
  border-style: solid;
  border-width: 1.5px;
  border-color: white;
  width: 98vw;
  height: 96vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -3;
  box-sizing: border-box;
  overflow: hidden;
}

section {
  min-height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.homecontents {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding-top: 21vh;
  z-index: 1;
}

#title {
  position: relative;
  font-size: 2.7em;
  cursor: default;
}

#changingtext {
  position: relative;
  top: -2.5em;
  animation: textIn .5s ease;
  cursor: default;
}

#description {
  position: relative;
  top: -1em;
  font-size: 1.6rem;
  cursor: default;
  width: 55%;
  border-top: solid;
}

.green-text{
  color: rgb(123, 209, 131);
}

.yellow-text{
  color: yellow;
}

.blue-text{
  color: rgb(131, 131, 255);
}

.tech-stack {
  text-decoration: none;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-top: 2vh;
  width: 100%;
  box-sizing: border-box;
}

.tech-icon-img {
  width: 30%;
  height: auto;
  filter: invert(100%);
}

.tech-icon-text {
  opacity: 0%;
  transition: opacity 0.3s ease;
}

.tech-icon-item:hover .tech-icon-text {
  opacity: 100%;
}

.copyright {
  position: fixed;
  bottom: 4vh;
  right: 2vw;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 0.4em;
  z-index: 5;
  pointer-events: none;
}

#Projects, #Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-tabs {
  display: flex;
  flex-direction: row;
  gap: 8em;
  padding-top: 8vh;
  text-decoration: none;
  list-style-type: none;
  z-index: 2;
}

.project-tabs li{
  cursor: pointer;
  padding: 0.75em 1em;
  transition: background-color 0.3s, color 0.3s;
}

.project-tabs .selected {
  background-color: white;
  color: black;
}

.project-tabs li:hover {
  background-color: #0078d4;
  color: white; 
}

/* For smaller screens */
@media screen and (max-width: 768px) {
  .boxborder {
    border-style: none;
  }

  section {
    overflow-x: visible;
  }

  #title, #changingtext {
    width: 75%;
  }

  .tech-stack {
    justify-content: center;
  }

  .project-tabs {
    gap: 0.5em;
    font-size: 1.5rem;
  }

  .copyright {
    position: fixed;
    width: 100%;
    bottom: 1vh;
    right: 0;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    font-family: sans-serif;
    font-size: 0.4em;
    z-index: 5;
  }
}
.projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
    grid-column-start: 1;
    grid-column-end: 3;
    width: 75%;
    padding-top: 5vh;
    padding-bottom: 10vh;
    gap: .5em;
    z-index: 2;
  }
  
  .project-card {
    height: 60vh;
    margin: 0.5em;
    color: black;
    border: 1px solid white;
    background-color: white;
    box-sizing: border-box;
    box-shadow: 0 0.5em 1em rgb(0, 0, 0);
    transition: transform 0.2s ease-in-out;
  }

  .project-card:hover {
    transform: translateY(-0.75em);
  }
  
  .project-card img {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.2s ease-in-out;
  }

  .project-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    box-sizing: border-box;
    padding: 1em;
    text-align: center;
    font-size: clamp(1.5rem, 3vw, 0.25em);
  }
  
  .project-card:hover .project-info {
    opacity: 1;
  }

  .proj-links {
    display: flex;
    align-items: center;
    gap: 2em;
  }

  .proj-links img {
    width: 1.25em;
    height: 1.25em;
  }
.WholeForm {
    box-sizing: border-box;
    position: relative;
    margin-top: 28vh;
    z-index: 3;
}

.FormContents {
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: center;
    width: 30vw;
    box-sizing: border-box;
    margin: auto;
}

.info {
    display: flex;
    gap: 1em;
    width: 100%;
}

.messagecontents {
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 100%;
}

.send {
    margin-top: -1vh;
    font-size: 0.8em;
    cursor: pointer;
    transition: opacity 0.2s ease-out;
    float: right;
    margin-left: -0.3vw;
}

.send:hover {
    opacity: 20%;
}

.send span {
    float: right;
}

button {
    background: none;
    border: none;
    font-size: 0.8em;
    font-family: 'Mate SC', serif;
    color: white;
    width: max-content;
}

input {
    border-style: solid;
    border-width: 1px;
    border-color: white;
    background-color: 0;
    width: 100%;
    background: transparent;
    color: white;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

textarea {
    border-style: solid;
    border-width: 1px;
    border-color: white;
    width: 100%;
    background: transparent;
    color: white;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    font-family: Arial, Helvetica, sans-serif;
    box-sizing: border-box;
}

label {
    font-size: 0.8em;
}

.info span {
    margin-top: 2vh;
}

@media only screen and (max-width: 768px) {
    .FormContents {
        top:5vh;
        z-index: 2;
    }
    .info {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
}

/* @media only screen and (max-height: 850px) {
    .WholeForm {
        margin-top: 10vh;
    }
} */

@media only screen and (max-height: 700px) {
    .WholeForm {
        margin-top: 0vh;
    }
}